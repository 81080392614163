<template>
  <div>
    <SettingsHeader :title="t('merchantSettings.rules.title')" :last-updated="lastUpdated" />

    <div class="section">
      <RulesSlippage :settings="settings" />
    </div>

    <div class="section">
      <RulesAutoRevoke :settings="settings" />
    </div>

    <div class="section">
      <RulesAntiSpam :settings="settings" />
    </div>

    <div v-if="!isPlanIo" class="section">
      <RulesSelfDestruct :settings="settings" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useSettings } from '@/composables/useSettings';
import { useUser } from '@/composables/useUser';

import SettingsHeader from '../../components/SettingsHeader.vue';

import RulesAntiSpam from './components/RulesAntiSpam.vue';
import RulesAutoRevoke from './components/RulesAutoRevoke.vue';
import RulesSelfDestruct from './components/RulesSelfDestruct.vue';
import RulesSlippage from './components/RulesSlippage.vue';

export default {
  name: 'SettingsRules',
  components: {
    SettingsHeader,
    RulesAutoRevoke,
    RulesAntiSpam,
    RulesSelfDestruct,
    RulesSlippage,
  },
  props: {
    lastUpdated: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { user } = useUser();
    const { settings } = useSettings();

    const isPlanIo = computed(() => user.value?.subscription?.plan === 'IO');

    return { settings, isPlanIo };
  },
};
</script>

<style lang="scss" scoped>
.section {
  border-bottom: 1px solid #E6E6E6;
  padding: 30px 0;
}
</style>
