<template>
  <SmoothModal
    :model-value="isOpenProp"
    width="440px"
    :has-header-divider="false"
    :has-header="false"
    @update:model-value="onClose"
  >
    <div class="info">
      <AppIcon name="edit" size="40px" is-img-tag style="margin-bottom: 20px;" />
      <AppText variant="div" size="17px" class="font-medium" mb="16px">
        {{ t('merchantSettings.changeName.title') }}
      </AppText>
      <transition name="fade">
        <AppText variant="div" :line-height="1.5" mb="16px">
          {{ description }}
        </AppText>
      </transition>
    </div>

    <ActionForm
      v-if="isNameCanChange && (step === steps.edit || step === steps.confirm)"
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      @validate="onConfirm"
    >
      <template #default="{ errors, action }">
        <Transition name="fade">
          <FInput
            v-if="step === steps.edit"
            v-model="requestFields.name"
            :validation-error="errors.name"
            class="name-input"
          />

          <div v-else-if="step === steps.confirm">
            <AppText variant="div" mb="4px" :line-height="1.5" :opacity="0.4">
              {{ t('merchantSettings.changeName.oldName') }}
            </AppText>
            <AppText variant="div" mb="16px" class="fake-field font-medium">
              {{ companyName }}
            </AppText>
            <AppText variant="div" mb="4px" :line-height="1.5" :opacity="0.4">
              {{ t('merchantSettings.changeName.newName') }}
            </AppText>
            <AppText variant="div" mb="30px" class="fake-field font-medium">
              {{ requestFields.name }}
            </AppText>
          </div>
        </Transition>

        <div class="d-flex" style="gap: 24px;">
          <FButton is-full-width @click="action">
            {{ t('common.continue') }}
          </FButton>
          <FButton is-full-width type="plain" @click="onClose">
            {{ t('common.cancel') }}
          </FButton>
        </div>
      </template>
    </ActionForm>
    <FButton
      v-if="!isNameCanChange"
      class="cancel-button"
      type="plain"
      is-full-width
      @click="onClose"
    >
      {{ t('common.close') }}
    </FButton>
  </SmoothModal>
</template>

<script>
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';

import SmoothModal from '@/components/SmoothModal';

import { i18n } from '@/plugins/localization';
import { useModel } from '@/composables/useModel';
import { useSettings } from '@/composables/useSettings';
import { useUser } from '@/composables/useUser';
import { ActionForm, companyNameRule, fieldValidation } from '@/validation';
import { updateMerchantName } from '@/views/merchant/MerchantSettings/api';

export default {
  name: 'ModalToChangeName',
  components: {
    SmoothModal,
    ActionForm,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { value: isOpenProp } = useModel(props, emit, { prop: 'modelValue' });

    const steps = Object.freeze({
      edit: 'edit',
      confirm: 'confirm',
    });

    const step = ref(steps.edit);

    const { companyName } = useUser();
    const { settings } = useSettings();
    const lastChangedAt = computed(() => settings?.value?.companyNameLastChangedAt);
    const daysPassed = computed(() => dayjs().diff(dayjs(lastChangedAt.value || dayjs()), 'day'));
    const daysLeft = computed(() => 180 - daysPassed.value);
    const isNameCanChange = computed(() => {
      const isFirstTime = settings.value.isInvitedByAdmin && !settings.value.isNameChanged;
      const isPassed = !lastChangedAt.value || (lastChangedAt.value && daysPassed.value >= 180);
      return !!(isFirstTime || isPassed);
    });

    const {
      validationSchema,
      requestFields,
      fieldsErrors,
      clearState,
    } = fieldValidation({ fieldName: 'name', rule: companyNameRule });

    watch(computed(() => companyName.value), (name) => {
      requestFields.name = name;
    }, { immediate: true });

    const onConfirm = () => {
      if (requestFields.name !== companyName.value && step.value === steps.edit) {
        if (isNameCanChange.value) {
          step.value = steps.confirm;
        } else {
          step.value = steps.note;
        }
      } else if (step.value === steps.confirm) {
        updateMerchantName(requestFields.name).then(() => {
          onClose();
        });
      }
    };

    const onClose = () => {
      isOpenProp.value = false;
      clearState();
      step.value = steps.edit;
      requestFields.name = companyName.value;
    };

    const { t } = i18n.global;
    const description = computed(() => {
      if (step.value === steps.confirm) {
        return t('merchantSettings.changeName.description.confirm');
      }

      if (!isNameCanChange.value) {
        return t('merchantSettings.changeName.description.tryAgain', { daysLeft: daysLeft.value });
      }

      return t('merchantSettings.changeName.description.default');
    });

    return {
      companyName,
      description,
      isOpenProp,
      isNameCanChange,
      steps,
      step,
      onClose,
      onConfirm,
      validationSchema,
      requestFields,
      fieldsErrors,
    };
  },
};
</script>

<style scoped lang="scss">
.name-input {
  margin-bottom: 30px;
}

.fake-field {
  background: #F7F7F7;
  border-radius: 4px;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.info {
  padding-top: 30px;
}

.cancel-button {
  margin-top: 40px;
}
</style>
