<template>
  <div class="profile">
    <SettingsHeader :key="language" :title="t('merchantSettings.general.title')" :last-updated="lastUpdated || null" />
    <div class="options">
      <AppText
        variant="div"
        size="15px"
        mt="5px"
        class="merchant-name font-medium"
      >
        {{ t('merchantSettings.general.merchantName') }}
      </AppText>

      <div class="merchant-info">
        <div class="d-flex align-items-center" style="gap:8px;">
          <TooltipCopy is-icon-hidden is-inlined>
            <AppText size="15px" class="font-medium">
              {{ companyName }}
            </AppText>
          </TooltipCopy>
          <ChangeNameButton />
        </div>
        <AppText
          variant="div"
          opacity="0.5"
          mt="4px"
        >
          {{ companyInfo }}
        </AppText>
      </div>

      <div class="picture">
        <AppText
          size="15px"
          class="font-medium"
          mb="8px"
          is-block
        >
          {{ t('merchantSettings.general.picture') }}
        </AppText><br>
        <AppText opacity="0.4">
          {{ t('merchantSettings.general.pictureDescription') }}
        </AppText>
      </div>
      <AvatarManagement
        :pictures="profilePictures"
        :is-exist="isAvatarExists"
        @save="onPictureSave"
        @delete="onPictureDelete"
      />

      <AppText size="15px" class="font-medium">
        {{ t('merchantSettings.general.timezone') }}
      </AppText>

      <TimezoneSelect
        v-model="currentTimezoneCode"
      />

      <AppText size="15px" class="font-medium">
        {{ t('merchantSettings.general.timeFormat') }}
      </AppText>

      <FSelect
        v-model="currentTimeFormat"
        :options="timeFormats"
        has-arrow
        option-text="label"
        option-value="value"
        no-margin
        return-key="value"
        popper-class="settings-select-popper"
      />

      <AppText size="15px" class="font-medium">
        {{ t('merchantSettings.general.dateFormat') }}
      </AppText>

      <FSelect
        v-model="currentDateFormat"
        :options="dateFormats"
        has-arrow
        option-text="label"
        option-value="value"
        no-margin
        return-key="value"
        popper-class="settings-select-popper"
      />

      <AppText size="15px" class="font-medium">
        {{ t('merchantSettings.general.numberFormat') }}
      </AppText>

      <FSelect
        v-model="currentNumberFormat"
        :options="numberFormats"
        has-arrow
        option-text="label"
        option-value="value"
        has-label-on-right
        no-margin
        return-key="value"
        popper-class="settings-select-popper"
      />

      <AppText size="15px" class="font-medium">
        {{ t('merchantSettings.general.language') }}
      </AppText>

      <FSelect
        v-model="currentLanguage"
        :options="languages"
        has-arrow
        disabled-field="soon"
        has-label-on-right
        no-margin
        popper-class="settings-select-popper"
        return-key="value"
        placement="top"
      >
        <template #option="{ item }">
          <div class="d-flex justify-content-between">
            <span>{{ item.text }}</span>
            <span v-if="item.soon">{{ item.soon }}</span>
          </div>
        </template>
      </FSelect>

      <div>&nbsp;</div>

      <div>
        <FButton
          no-margin
          :loading="isSaveProcessing"
          @click="onUpdateMerchantSettings"
        >
          {{ t('merchantSettings.general.saveChanges') }}
        </FButton>
      </div>
    </div>
    <div class="badge">
      <UidBadge :uid="uid" type="block" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import AvatarManagement from '@/components/Modules/AvatarManagement.vue';
import UidBadge from '@/components/UidBadge.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import TimezoneSelect from '@/components/Modules/TimezoneSelect.vue';

import { setAppLanguage, updateMerchantSettings } from '@/api/merchant';
import { successHandler } from '@/composables/useFetch';
import { currency } from '@/composables/useCurrency';
import { useLang } from '@/composables/useLang';
import { useSettings } from '@/composables/useSettings';
import { useUser } from '@/composables/useUser';
import { getOffsetByTimezone } from '@/composables/useTimezones';
import {
  currencyList, dateFormats, numberFormats, timeFormats,
} from '@/common/data';
import { i18n } from '@/plugins/localization';

import SettingsHeader from '../../components/SettingsHeader.vue';
import { deleteProfilePhoto, saveProfilePhoto } from '../../api';

import ChangeNameButton from './ChangeNameButton.vue';

export default {
  name: 'SettingsGeneral',
  components: {
    SettingsHeader,
    UidBadge,
    AvatarManagement,
    TooltipCopy,
    TimezoneSelect,
    ChangeNameButton,
  },
  props: {
    lastUpdated: {
      type: String,
      default: null,
    },
  },
  emits: ['update-data'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const {
      companyName, isAvatarExists, uid, user,
    } = useUser();
    const { language, languages } = useLang();
    const {
      dateFormat, timeFormat, timezoneCode, numberFormat, settings,
    } = useSettings();

    const currentLanguage = ref(settings.value.language);
    const currentDateFormat = ref(dateFormat.value);
    const currentTimeFormat = ref(timeFormat.value);
    const currentNumberFormat = ref(numberFormat.value);

    const processedTimezone = getOffsetByTimezone(timezoneCode.value);
    const currentTimezoneCode = ref(`${timezoneCode.value} (${processedTimezone})`);

    const profilePictures = computed(() => ({
      profileAvatar: user.value?.profileAvatar,
      profileOriginalAvatar: user.value?.profileOriginalAvatar,
    }));

    const shouldPicturesBeenDeleted = ref(false);
    const pictures = ref({});
    const onPictureSave = ({ originalImage, croppedImage }) => {
      pictures.value = { originalImage, croppedImage };
      shouldPicturesBeenDeleted.value = false;
    };
    const onPictureDelete = () => {
      shouldPicturesBeenDeleted.value = true;
      pictures.value = { originalImage: null, croppedImage: null };
    };

    const shouldSettingsBeingUpdated = computed(() => currentLanguage.value !== settings.value.language
      || currentTimezoneCode.value !== timezoneCode.value
      || currentDateFormat.value !== dateFormat.value
      || currentTimeFormat.value !== timeFormat.value
      || currentNumberFormat.value !== numberFormat.value);

    const isSaveProcessing = ref(false);
    const onUpdateMerchantSettings = async () => {
      isSaveProcessing.value = true;

      const hasBeenEdited = shouldSettingsBeingUpdated.value
      || pictures.value.originalImage
      || pictures.value.croppedImage
      || shouldPicturesBeenDeleted.value;

      if (shouldSettingsBeingUpdated.value) {
        const finalSettingsObject = {
          language: currentLanguage.value,
          dateFormat: currentDateFormat.value,
          timeFormat: currentTimeFormat.value,
          numberFormat: currentNumberFormat.value,
        };

        if (currentTimezoneCode.value) {
          if (currentTimezoneCode.value === 'UTC') {
            finalSettingsObject.timezoneCode = undefined;
          } else {
            const tz = currentTimezoneCode.value.split(' (')[0];
            finalSettingsObject.timezoneCode = tz;
          }
        }

        await updateMerchantSettings(finalSettingsObject);

        setAppLanguage();
      }

      if (pictures.value.originalImage || pictures.value.croppedImage) {
        const { isSuccess } = await saveProfilePhoto(pictures.value);
        if (isSuccess && !shouldSettingsBeingUpdated.value) {
          successHandler(t('merchantSettings.notification.saveProfilePictureSuccess'));
        }
      }
      if (shouldPicturesBeenDeleted.value && isAvatarExists.value) {
        const { isSuccess } = await deleteProfilePhoto();
        if (isSuccess && !shouldSettingsBeingUpdated.value) {
          successHandler(t('merchantSettings.notification.deleteProfilePictureSuccess'));
        }
      }

      if (hasBeenEdited) {
        emit('update-data');
      }

      shouldPicturesBeenDeleted.value = false;

      pictures.value = { originalImage: null, croppedImage: null };

      isSaveProcessing.value = false;
    };

    const companyInfo = computed(() => {
      const selectedCurrency = currencyList.find((item) => item.short === currency.value.value);

      return `${t(`currency.${selectedCurrency.countryCode}`)} (${currency.value.value})`;
    });

    return {
      profilePictures,
      companyName,
      isAvatarExists,
      uid,
      onUpdateMerchantSettings,
      currentLanguage,
      currentDateFormat,
      currentTimeFormat,
      currentNumberFormat,
      currentTimezoneCode,
      language,
      languages,
      dateFormats,
      timeFormats,
      numberFormats,
      companyInfo,

      onPictureSave,
      onPictureDelete,
      isSaveProcessing,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-wrap: wrap;

  :deep(.header) {
    width: 100%;
    margin-bottom: 30px;
  }

  .picture, :deep(.avatar-manage) {
    margin-bottom: 20px;
  }

  .options {
    display: grid;
    grid-template-columns: 124px 1fr;
    grid-template-rows: 64px 1fr 44px 44px 44px 44px;
    gap: 20px 60px;
    grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". ."
    ". ."
    ". .";
    align-items: center;
    margin-right: 120px;

    .merchant-name, .merchant-info {
      align-self: start;
    }
  }

  .badge {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  :deep(.el-select) {
    width: 340px;
  }
}
</style>
