<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="440px"
    :has-header="false"
    @update:model-value="closeModal"
  >
    <StepsWrapper
      :icon="currentStep === steps.GEN ? 'shield-2fa' : 'bomb'"
      :steps="Object.values(steps)"
      :current-step="currentStep"
    >
      <template #title>
        {{ currentStep === steps.GEN
          ? t('merchantSettings.detonatorModal.setDetonator.title')
          : t('merchantSettings.detonatorModal.detonationCode.title') }}
      </template>
      <template #description>
        {{ currentStep === steps.GEN
          ? t('merchantSettings.detonatorModal.setDetonator.description')
          : t('merchantSettings.detonatorModal.detonationCode.description') }}
      </template>
      <template v-if="currentStep === steps.GEN">
        <ActionForm
          :request-fields="requestFields"
          :validation-schema="validationSchema"
          :response-errors="fieldsErrors"
          @validate="onGenCode"
        >
          <template #default="{ errors, action }">
            <SplitInput
              v-model="requestFields.code"
              type="dashed"
              :state="errors?.code ? 'error' : ''"
              is-auto-clear-error
              :error-message="errors.code?.message"
            />

            <div class="buttons">
              <FButton is-full-width type="danger" @click="action">
                {{ t('merchantSettings.detonatorModal.generateCode') }}
              </FButton>
              <FButton is-full-width type="plain" @click="closeModal">
                {{ t('common.cancel') }}
              </FButton>
            </div>
          </template>
        </ActionForm>
      </template>
      <template v-if="currentStep === steps.CODE">
        <TooltipCopy color="var(--color-error)" is-icon-hidden style="margin-bottom: 40px;">
          <AppText variant="div" size="21px" class="code font-semibold">
            {{ detonatorCode }}
          </AppText>
        </TooltipCopy>
        <FButton
          no-margin
          is-full-width
          @click="closeModal"
        >
          {{ t('merchantSettings.detonatorModal.savedCode') }}
        </FButton>
      </template>
    </stepswrapper>
  </SmoothModal>
</template>

<script setup>
import { ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';
import StepsWrapper from '@/components/Containers/StepsWrapper.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { enableDetonator } from '@/api/merchant';
import { ActionForm } from '@/validation';
import { codeRule } from '@/validation/rules';
import { fieldValidation } from '@/validation/schemas';

const isModalOpen = defineModel({ type: Boolean, default: false });
const detonatorCode = ref('');

const steps = Object.freeze({
  GEN: 'gen',
  CODE: 'code',
});

const currentStep = ref(steps.GEN);

const {
  validationSchema,
  requestFields,
  fieldsErrors,
  processError,
  clearState,
} = fieldValidation({ fieldName: 'code', rule: codeRule });

const onGenCode = async () => {
  const { data, isSuccess, errorObject } = await enableDetonator(requestFields.code);

  if (isSuccess) {
    currentStep.value = steps.CODE;
    detonatorCode.value = data;
    clearState();
  } else {
    processError(errorObject, 'wrongToken', 'code');
  }
};

const closeModal = () => {
  currentStep.value = steps.GEN;
  isModalOpen.value = false;
};
</script>

<style lang="scss" scoped>
.code {
  width: 360px;
  text-transform: uppercase;
  height: 64px;
  border: 2px solid var(--color-error-03);
  background-color: var(--color-error-01);
  color: var(--color-error);
  border-radius: 4px;
  @include flex-center;
}

.buttons {
  display: flex;
  gap: 24px;

  & > * {
    width: 50%;
  }
}
</style>
