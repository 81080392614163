<template>
  <div class="name-button" @click="isModalOpen = !isModalOpen">
    <AppIcon name="pencil" size="14px" />
  </div>

  <ModalToChangeName v-model="isModalOpen" />
</template>

<script>
import { ref } from 'vue';

import ModalToChangeName from './ModalToChangeName.vue';

export default {
  name: 'ChangeNameButton',
  components: {
    ModalToChangeName,
  },
  setup() {
    const isModalOpen = ref(false);

    return { isModalOpen };
  },
};
</script>

<style scoped lang="scss">
.name-button {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #F2F2F2;
    @include transition-base('background');

    :deep(.icon path) {
      fill: var(--color-primary);
      @include transition-base('fill');
    }
  }
}
</style>
